/* eslint-disable prettier/prettier */
import Vue from "vue";
import VueRouter from "vue-router";
import IndexView from "@/views/IndexView.vue";
import ScreenMobile from "@/views/ScreenMobile.vue";
import ScreenPc from "@/views/ScreenPc.vue";

Vue.use(VueRouter);
// //获取原型对象上的push函数
// const originalPush = VueRouter.prototype.push;
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

const routes = [
  {
    path: "/",
    redirect: '/screenPc'
  },
  {
    path: "/index",
    name: "index",
    component: IndexView,
  },
  {
    path: "/screenMobile",
    name: "screenMobile",
    component: ScreenMobile,
    meta: {
      title: '手机端大屏'
    }
  },
  {
    path: "/screenPc",
    name: "screenPc",
    component: ScreenPc,
    meta: {
      title: '电脑端大屏'
    }
  }
];

const router = new VueRouter({
  routes,
});

export default router;
