import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/BaoUi.css";
import "@/assets/css/main.css";
import "@/assets/css/swiper-bundle.min.css";
import "element-ui/lib/theme-chalk/index.css";

//使用钩子函数对路由进行权限跳转
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
