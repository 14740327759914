import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";

const service = axios.create({
  // baseURL: "/api", // api的base_url
  baseURL: "https://meat.chinausmeat.com", // api的base_url
  timeout: 30000, // 请求超时时间
  withCredentials: false,
});

service.interceptors.request.use(
  (config) => {
    if (config.url.includes("/api/")) {
      config.baseURL = "http://ipsc.jiancewangluo.cn/";
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code == 1) {
      return response.data;
    }
    if (response.data.resultCode == 1) {
      // if (response.headers.authorization) {
      //   localStorage.setItem("token", response.headers.authorization);
      // }
      return response.data;
    } else {
      if (response.data.resultCode == 4001) {
        router.push("/login");
      } else {
        Message.error(response.data.resultMsg);
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
