<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  beforeUnmount() {},
  created() {},
  mounted() {
    // 通过正则表达式的test方法判断是否包含“Mobile”字符串
    const isPhone = /mobile/i.test(navigator.userAgent);
    // 如果包含“Mobile”（是手机设备）则返回true
    console.log("isPhoneA", isPhone);
    this.isPhone = isPhone;
    window.location.href =
      window.location.origin +
      (isPhone ? "/#/screenMobile" : "/#/screenPc") +
      "?bsId=" +
      this.$route.query.bsId;
  },
  methods: {},
};
</script>

<style scoped></style>
