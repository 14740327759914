import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    uuid: "",
  },
  getters: {},
  mutations: {
    setUuid(state, uuid) {
      state.uuid = uuid;
    },
  },
  actions: {
    commitUuid(context, uuid) {
      context.commit("setUuid", uuid);
    },
  },
  modules: {},
});
