<template>
  <div class="w-full h-screen bg_color flex at_c jc_c">
    <div class="screen_pc" :style="style">
      <div class="body">
        <div class="header">
          <div class="time_info_left">
            <img class="logo" src="@/assets/img/logo.png" alt="" />
            {{ currentDate }} {{ currentTime }}
          </div>
          <div class="title">{{ titleInfo.name }}</div>
          <div class="time_info_right">
            农历{{ lunarDate }} {{ currentWeek }}
          </div>
        </div>
        <div class="block_main">
          <div class="block_first_left">
            <div class="border block_cell1">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[0].titleName }}</span>
              </div>
              <div>
                <div class="gauge_groups">
                  <div id="echarts_gauge1"></div>
                  <div id="echarts_gauge2"></div>
                  <div id="echarts_gauge3"></div>
                  <div id="echarts_gauge4"></div>
                  <div id="echarts_gauge5"></div>
                  <div id="echarts_gauge6"></div>
                  <div id="echarts_gauge7"></div>
                  <div id="echarts_gauge8"></div>
                </div>
                <div id="echarts_line1"></div>
              </div>
            </div>
            <div class="border block_cell2">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[3].titleName }}</span>
              </div>
              <div class="rank_list">
                <div class="rank_list_head">
                  <div>交易榜单</div>
                  <div>订单榜单</div>
                  <div>商品榜单</div>
                  <div>店长榜单</div>
                  <div>团长榜单</div>
                </div>
                <div class="rank_list_body">
                  <div
                    class="rank_list_cell"
                    v-for="(item, index) in topList"
                    :key="index"
                  >
                    <div>{{ item.trade }}</div>
                    <div>{{ item.order }}</div>
                    <div>{{ item.goods }}</div>
                    <div>{{ item.store }}</div>
                    <div>{{ item.team }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border block_cell3">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[7].titleName }}</span>
                <div class="unit">单位：万元</div>
              </div>
              <div class="rongzi_list">
                <div v-for="(item, index) in custmData3" :key="index">
                  <div>{{ item.name }}</div>
                  <div>{{ item.val }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="block_first_center">
            <div class="border block_cell4 flex at_c jc_a">
              <div
                class="info_span"
                v-for="(item, index) in custmData4"
                :key="index"
              >
                <div>{{ item.name }}=</div>
                <div>{{ item.val }}</div>
              </div>
            </div>
            <div class="border block_cell5">
              <div id="echarts_map"></div>
            </div>
            <div class="border block_cell6">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[5].titleName }}</span>
                <div class="search_cell">
                  <el-input v-model="searhTitle" placeholder=""></el-input>
                  <img
                    class="can"
                    @click="searchNews"
                    src="@/assets/img/icon_search.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="news_list">
                <div
                  class="news_cell"
                  v-for="(item, index) in newsList"
                  :key="index"
                  @click="openPage(item.newsLink)"
                >
                  <div class="news_cell_time">{{ item.modifTimeText }}</div>
                  <div class="news_cell_title hang1 can">
                    {{ item.title }}
                  </div>
                  <div class="news_cell_arrow">></div>
                </div>
              </div>
            </div>
            <!-- 广告位 -->
            <div class="border block_cell7">
              <el-carousel height="106px" :pause-on-hover="true" arrow="never">
                <el-carousel-item
                  v-for="(item, index) in advertList"
                  :key="index"
                >
                  <div
                    class="custom_carousel"
                    :class="{ can: item.type != 'video' && item.linkAdress }"
                    @click="openPage(item.linkAdress)"
                  >
                    <img v-if="item.type == 'img'" :src="item.adUrl" alt="" />
                    <video v-else :src="item.adUrl" controls></video>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="border block_cell8">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[8].titleName }}</span>
              </div>
              <div class="gauge_groups1">
                <div id="echarts_gauge9"></div>
                <div id="echarts_gauge10"></div>
                <div id="echarts_gauge11"></div>
              </div>
            </div>
          </div>
          <div class="block_first_right">
            <div class="border block_cell9">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[1].titleName }}</span>
                <div class="unit">单位：元/斤</div>
              </div>
              <div>
                <div class="hangqing_group">
                  <div
                    class="hangqing_cell"
                    v-for="(item, index) in dayPriceInfo"
                    :key="index"
                  >
                    <div>{{ item.name }}</div>
                    <div>{{ item.val }}</div>
                  </div>
                </div>
                <div id="echarts_line2"></div>
              </div>
            </div>
            <div class="border block_cell10">
              <div class="category_head" id="myElement">
                <div
                  @click="selectType(item.typeId)"
                  v-for="(item, index) in typeList"
                  :key="index"
                  :class="{ active: typeId == item.typeId }"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="news_list1">
                <div
                  class="news_cell1"
                  v-for="(item, index) in articleList"
                  :key="index"
                  @click="openPage(item.newsLink)"
                >
                  <div class="news_cell1_time">{{ item.modifTimeText }}</div>
                  <div class="news_cell1_title hang1 can">
                    {{ item.title }}
                  </div>
                  <div class="news_cell1_arrow">></div>
                </div>
              </div>
              <div class="flex at_c jc_c mt5">
                <el-pagination
                  :background="false"
                  layout="prev, pager, next"
                  :current-page="pageTou"
                  :total="totalTou"
                  :page-size="pageSizeTou"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
            <div class="border block_cell11">
              <div class="block_title block_title_line">
                <span>{{ titleInfo.modList[9].titleName }}</span>
                <div class="unit">单位：万元</div>
              </div>
              <div class="rongzi_list1">
                <div v-for="(item, index) in custmData1" :key="index">
                  <div>{{ item.name }}</div>
                  <div>{{ item.val }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { formatTime, getLunar, numberWithCommas } from "@/utils/common";
import * as Service from "@/api/index";
import "@/utils/china.js";
export default {
  data() {
    return {
      style: "",
      width: 1920,
      height: 1080,
      isPhone: true,

      bsId: null,
      titleInfo: {
        modList: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      },
      advertList: [], // 广告位列表
      dataViewInfo: [], // 数据概览
      dataViewList: [], // 数据概览折线图
      dayPriceInfo: [], // 行情概览
      dayPriceList: [], // 行情概览折线图
      custmData1: [], // 科技成果
      custmData2: [], // 碳减值
      custmData3: [], // 融资额
      custmData4: [], // 指数贡献
      mapList: [], // 地图数据
      topList: [], // 排行榜
      typeList: [], // 分类列表
      articleList: [], // 文章列表
      typeId: null, // 当前头条分类id
      pageTou: 0,
      totalTou: 0,
      pageSizeTou: 3, // 头条分页
      pageNews: 3,
      totalNews: 3,
      pageSizeNews: 3, // 头条分页
      newsList: [], // 新闻列表
      searhTitle: "", // 搜索新闻标题

      currentDate: "",
      currentTime: "",
      currentWeek: "",
      lunarDate: "",
    };
  },
  mounted() {
    this.style = {
      width: this.width + "px",
      height: this.height + "px",
      transform: "scale(1) translate(-50%, -50%)",
    };
    this.setScale();
    window.onresize = this.Debounce(this.setScale, 1000);

    this.bsId = this.$route.query.bsId;
    //获取当前时间
    this.showRealTime();

    this.getTitleInfo();
    this.getAdvertList();
    this.getCustmDataList1();
    this.getCustmDataList2();
    this.getCustmDataList3();
    this.getCustmDataList4();
    this.getDataView();
    this.getDayPrice();
    this.getProvMap();
    this.getRank();
    this.getAllType();
    this.getNewsList(1);
  },
  methods: {
    Debounce(fn, t) {
      const delay = t || 500;
      let timer;
      return function () {
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => {
          timer = null;
          fn.apply(context, args);
        }, delay);
      };
    },
    // 获取放大缩小比例
    getScale() {
      const w = window.innerWidth / this.width;
      const h = window.innerHeight / this.height;
      return w < h ? w : h;
    },
    // 设置比例
    setScale() {
      this.style.transform =
        "scale(" + this.getScale() + ") translate(-50%, -50%)";
      // console.log('任你千变万化,我都不会影响性能')
    },
    // 获取大屏端的模块标题列表
    async getTitleInfo() {
      const res = await Service.getTitleInfo({
        bsId: this.bsId,
      });
      console.log("获取大屏端的模块标题列表", res);
      this.titleInfo = res.data;
    },
    // 获取看版下展示的广告位文件列表
    async getAdvertList() {
      const res = await Service.getAdvertList({
        bs_id: this.bsId,
      });
      console.log("获取看版下展示的广告位文件列表", res);
      var advertList = res.data;
      for (const item of advertList) {
        item.type = item.adUrl.includes(".mp4") ? "video" : "img";
      }
      this.advertList = res.data;
    },
    // 获取科技成果数据组
    async getCustmDataList1() {
      const res = await Service.getCustmDataList({
        bs_id: this.bsId,
        type: 1,
      });
      console.log("获取科技成果数据组", res);
      var custmData = res.data.data;
      var arr = [];
      for (const index in custmData) {
        arr.push({
          name: index,
          val: numberWithCommas(custmData[index]),
        });
      }
      this.custmData1 = arr;
    },
    // 获取碳减值贡献数据组
    async getCustmDataList2() {
      const res = await Service.getCustmDataList({
        bs_id: this.bsId,
        type: 2,
      });
      console.log("获取碳减值贡献数据组", res);
      var custmData = res.data.data;
      var arr = [];
      for (const index in custmData) {
        arr.push({
          name: index,
          value: custmData[index],
        });
      }
      this.custmData2 = arr;
      this.echartsGauge2();
    },
    // 获取融资额数据组
    async getCustmDataList3() {
      const res = await Service.getCustmDataList({
        bs_id: this.bsId,
        type: 3,
      });
      console.log("获取融资额数据组", res);
      var custmData = res.data.data;
      var arr = [];
      for (const index in custmData) {
        arr.push({
          name: index,
          val: numberWithCommas(custmData[index]),
        });
      }
      this.custmData3 = arr;
    },
    // 获取指数贡献数据组
    async getCustmDataList4() {
      const res = await Service.getCustmDataList({
        bs_id: this.bsId,
        type: 4,
      });
      console.log("获取指数贡献数据组", res);
      var custmData = res.data.data;
      var arr = [];
      for (const index in custmData) {
        arr.push({
          name: index,
          val: custmData[index],
        });
      }
      this.custmData4 = arr;
    },
    // 获取数据概览信息+近几天折线数据节点
    async getDataView() {
      const res = await Service.getDataView({
        bs_id: this.bsId,
      });
      console.log("获取数据概览信息+近几天折线数据节点", res);
      var dataViewInfo = res.data.info;
      var arr = [];
      for (const index in dataViewInfo) {
        arr.push({
          name: index,
          value: dataViewInfo[index] * 1,
        });
      }
      this.dataViewInfo = arr;
      this.dataViewList = res.data.list;
      this.echartsGauge1();
      this.echartsLine1();
    },
    // 获取今日行情概览信息+近几天折线数据节点
    async getDayPrice() {
      const res = await Service.getDayPrice({
        bs_id: this.bsId,
      });
      console.log("获取今日行情概览信息+近几天折线数据节点", res);
      var dayPriceInfo = res.data.info;
      var arr = [];
      for (const index in dayPriceInfo) {
        arr.push({
          name: index,
          val: dayPriceInfo[index],
        });
      }
      this.dayPriceInfo = arr;
      this.dayPriceList = res.data.list;
      this.echartsLine2();
    },
    // 查询最近全国数据列表(各省地图销量)
    async getProvMap() {
      const res = await Service.getProvMap({
        bs_id: this.bsId,
      });
      console.log("查询最近全国数据列表(各省地图销量)", res);
      var mapList = res.data.provData;
      var arr = [];
      for (const item of mapList) {
        arr.push({
          name: item.province,
          value: item.amount * 1,
          orderNum: item.orderNum * 1 || 0,
        });
      }
      this.mapList = arr;
      this.echartsMap();
    },
    // 获取最新榜单列表
    async getRank() {
      const res = await Service.getRank({
        bs_id: this.bsId,
      });
      console.log("获取最新榜单列表", res);
      var arr = [];
      var arr1 = res.data.tradeList;
      var arr2 = res.data.orderList;
      var arr3 = res.data.goodsList;
      var arr4 = res.data.storeList;
      var arr5 = res.data.teamList;
      for (const index in arr1) {
        arr.push({
          trade: arr1[index],
          order: arr2[index],
          goods: arr3[index],
          store: arr4[index],
          team: arr5[index],
        });
      }
      this.topList = arr;
    },
    // 获取所有头条类型
    async getAllType() {
      const res = await Service.getAllType({
        bs_id: this.bsId,
      });
      console.log("获取所有头条类型", res);
      this.typeList = res.data;
      setTimeout(() => {
        this.CreatDrag("myElement");
      }, 1000);
      this.selectType(this.typeList[0].typeId);
    },
    // 选择头条类型
    selectType(typeId) {
      this.articleList = [];
      this.pageTou = 1;
      this.typeId = typeId;
      this.getTouList(1);
    },
    handleCurrentChange(e) {
      this.pageTou = e;
      this.getTouList(e);
    },
    // 获取不同类型[头条]列表
    async getTouList(page) {
      const res = await Service.getTouList({
        bs_id: this.bsId,
        typeId: this.typeId,
        page,
        pageSize: this.pageSizeTou,
      });
      console.log("获取不同类型[头条]列表", res);
      var list = res.data;
      for (const item of list) {
        item.modifTimeText = formatTime(item.modifTime / 1000, "M月D日");
      }
      this.pageTou = page;
      this.articleList = list;
      this.totalTou = res.count;
    },
    // 获取[新闻动态]列表（+搜索）
    async getNewsList(page) {
      const res = await Service.getNewsList({
        bs_id: this.bsId,
        title: this.searhTitle,
        page,
        pageSize: this.pageSizeNews,
      });
      console.log("获取[新闻动态]列表（+搜索）", res);
      var list = res.data;
      for (const item of list) {
        item.modifTimeText = formatTime(item.modifTime / 1000, "M月D日");
      }
      this.newsList = list;
    },
    openPage(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    searchNews() {
      this.newsList = [];
      this.getNewsList(1);
    },
    CreatDrag(id) {
      var oBox = document.getElementById(id);
      oBox.onmousedown = function (ev) {
        // eslint-disable-next-line no-redeclare
        var ev = ev || event;
        var Y = ev.clientY;
        var X = ev.clientX;
        var ToTop = document.getElementById(id).scrollTop;
        var Toleft = document.getElementById(id).scrollLeft;
        oBox.onmousemove = function (ev) {
          ev = ev || event;
          var subY = ev.clientY - Y;
          var subX = ev.clientX - X;
          Y = ev.clientY;
          X = ev.clientX;
          ToTop -= subY;
          Toleft -= subX;
          document.getElementById(id).scrollTop = ToTop;
          document.getElementById(id).scrollLeft = Toleft;
        };
        document.onmouseup = function () {
          oBox.onmousemove = function () {
            null;
          };
        };
      };
    },
    //当前日期时间
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var date = d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date =
        year +
        "-" +
        (month > 9 ? month : "0" + month) +
        "-" +
        (date > 9 ? date : "0" + date);
      var cori_time = hour + ":" + min + ":" + sec;
      this.currentDate = cori_date;
      this.currentTime = cori_time;
      this.currentWeek = "星期" + days[day];
      this.lunarDate = getLunar(cori_date);
      setTimeout(() => {
        this.showRealTime();
      }, 1000);
    },
    echartsGauge1() {
      var dataViewInfo = this.dataViewInfo;
      // const val = 70 // 看多数
      // const dataVal = val / 100 // 看多数百分比
      // 基于准备好的dom，初始化echarts实例
      const myChart1 = echarts.init(document.getElementById("echarts_gauge1"));
      const myChart2 = echarts.init(document.getElementById("echarts_gauge2"));
      const myChart3 = echarts.init(document.getElementById("echarts_gauge3"));
      const myChart4 = echarts.init(document.getElementById("echarts_gauge4"));
      const myChart5 = echarts.init(document.getElementById("echarts_gauge5"));
      const myChart6 = echarts.init(document.getElementById("echarts_gauge6"));
      const myChart7 = echarts.init(document.getElementById("echarts_gauge7"));
      const myChart8 = echarts.init(document.getElementById("echarts_gauge8"));
      // 绘制图表
      myChart1.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            // min: 0,
            // max: 100000,
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#01EC92",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[0]],
          },
        ],
      });
      myChart2.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#F9AE3A",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[1]],
          },
        ],
      });
      myChart3.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#FD405A",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[2]],
          },
        ],
      });
      myChart4.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#02B6EF",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[3]],
          },
        ],
      });
      myChart5.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            // min: 0,
            // max: 100000,
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#01EC92",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[4]],
          },
        ],
      });
      myChart6.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#F9AE3A",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[5]],
          },
        ],
      });
      myChart7.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#FD405A",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[6]],
          },
        ],
      });
      myChart8.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "75%"],
            },
            type: "gauge",
            startAngle: 205,
            endAngle: 335,
            radius: "100%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                color: "#02B6EF",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 56757,
            //   },
            // ],
            data: [dataViewInfo[7]],
          },
        ],
      });
    },
    echartsGauge2() {
      // const val = 70 // 看多数
      // const dataVal = val / 100 // 看多数百分比
      // 基于准备好的dom，初始化echarts实例
      var custmData2 = this.custmData2;
      const myChart9 = echarts.init(document.getElementById("echarts_gauge9"));
      const myChart10 = echarts.init(
        document.getElementById("echarts_gauge10")
      );
      const myChart11 = echarts.init(
        document.getElementById("echarts_gauge11")
      );
      // 绘制图表
      myChart9.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "145%"],
            },
            type: "gauge",
            startAngle: 90,
            endAngle: 450,
            center: ["50%", "45%"],
            radius: "60%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                color: "#32B16C",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 70,
            //   },
            // ],
            data: [custmData2[0]],
          },
        ],
      });
      myChart10.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "145%"],
            },
            type: "gauge",
            startAngle: 90,
            endAngle: 450,
            center: ["50%", "45%"],
            radius: "60%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                color: "#80C269",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 70,
            //   },
            // ],
            data: [custmData2[1]],
          },
        ],
      });
      myChart11.setOption({
        series: [
          {
            title: {
              show: true,
              fontSize: 12,
              color: "#fff",
              offsetCenter: [0, "145%"],
            },
            type: "gauge",
            startAngle: 90,
            endAngle: 450,
            center: ["50%", "45%"],
            radius: "60%",
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                color: "#13B5B1",
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 6,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0],
            },
            // data: [
            //   {
            //     name: "入驻企业数",
            //     value: 70,
            //   },
            // ],
            data: [custmData2[2]],
          },
        ],
      });
    },
    echartsLine1() {
      var dataViewList = this.dataViewList;
      var dateArr = [];
      var list1 = [];
      var list2 = [];
      for (const item of dataViewList) {
        dateArr.unshift(formatTime(item.modifDate / 1000, "Y-M-D"));
        list1.unshift(item.gmv);
        list2.unshift(item.orderNum);
      }
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_line1"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.6)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          icon: "rect",
          data: [
            {
              name: "GMV趋势图",
            },
            {
              name: "订单趋势图",
            },
          ],
          x: "center",
          textStyle: {
            align: "right",
            color: "#fff",
            fontSize: 18,
          },
          bottom: 5,
        },
        grid: {
          top: "30px",
          left: "100px",
          right: "70px",
          bottom: "70px",
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          // data: [
          //   "2022-10-11",
          //   "2022-10-12",
          //   "2022-10-13",
          //   "2022-10-14",
          //   "2022-10-15",
          //   "2022-10-16",
          //   "2022-10-17",
          // ],
          data: dateArr,
          scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
          axisLine: {
            // x轴
            show: true,
          },
          axisTick: {
            // x轴刻度线
            show: true,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              align: "right",
            },
            axisLine: {
              // y轴线
              show: false,
            },
            axisTick: {
              // y轴刻度
              show: false,
            },
            axisLabel: {
              // show :false,
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: "#E9E9E9",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            color: "#00FFFF",
            name: "GMV趋势图",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90, 230, 210],
            data: list1,
            showSymbol: true, // 拐点tooltip
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#00FFFF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "transparent", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          {
            color: "#FF0000",
            name: "订单趋势图",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [220, 232, 101, 134, 490, 230, 2100],
            data: list2,
            showSymbol: true, // 拐点tooltip
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FF0000", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "transparent", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
    echartsLine2() {
      var dayPriceList = this.dayPriceList;
      var dateArr = [];
      var list1 = [];
      var list2 = [];
      var list3 = [];
      for (const item of dayPriceList) {
        dateArr.unshift(formatTime(item.modifDate / 1000, "Y-M-D"));
        list1.unshift(item.pigPrice);
        list2.unshift(item.porkPrice);
        list3.unshift(item.fodderPrice);
      }
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_line2"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        title: {
          text: "趋势图",
          textStyle: {
            color: "#fff",
            fontSize: 20,
            fontWeight: "bold",
          },
          left: 50,
          top: 20,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.6)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          icon: "rect",
          data: [
            {
              name: "猪价",
            },
            {
              name: "肉价",
            },
            {
              name: "原料",
            },
          ],
          // x: "center",
          textStyle: {
            align: "right",
            color: "#fff",
            fontSize: 16,
          },
          top: 20,
          right: 50,
        },
        grid: {
          top: "70px",
          left: "100px",
          right: "70px",
          bottom: "40px",
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          // data: [
          //   "2022-10-11",
          //   "2022-10-12",
          //   "2022-10-13",
          //   "2022-10-14",
          //   "2022-10-15",
          //   "2022-10-16",
          //   "2022-10-17",
          // ],
          data: dateArr,
          scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
          axisLine: {
            // x轴
            show: true,
          },
          axisTick: {
            // x轴刻度线
            show: true,
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              align: "right",
            },
            axisLine: {
              // y轴线
              show: false,
            },
            axisTick: {
              // y轴刻度
              show: false,
            },
            axisLabel: {
              // show :false,
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: "#E9E9E9",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            color: "#1897FF",
            name: "猪价",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90, 230, 210],
            data: list1,
            showSymbol: true, // 拐点tooltip
          },
          {
            color: "#FF5816",
            name: "肉价",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [220, 232, 101, 134, 490, 230, 2100],
            data: list2,
            showSymbol: true, // 拐点tooltip
          },
          {
            color: "#1AE1D9",
            name: "原料",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [520, 32, 101, 134, 420, 230, 20],
            data: list3,
            showSymbol: true, // 拐点tooltip
          },
        ],
      });
    },
    echartsMap() {
      let dataList = [
        {
          name: "北京",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "南海诸岛",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "天津",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "上海",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "重庆",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "河北",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "河南",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "云南",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "辽宁",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "黑龙江",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "湖南",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "安徽",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "山东",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "新疆",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "江苏",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "浙江",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "江西",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "湖北",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "广西",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "甘肃",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "山西",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "内蒙古",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "陕西",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "吉林",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "福建",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "贵州",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "广东",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "青海",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "西藏",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "四川",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "宁夏",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "海南",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "台湾",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "香港",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
        {
          name: "澳门",
          value: 0,
          tooltip: {
            orderNum: 0,
          },
        },
      ];
      var mapList = this.mapList;
      for (const item of dataList) {
        for (const items of mapList) {
          if (item.name == items.name) {
            item.value = items.value;
            item.tooltip.orderNum = items.orderNum;
          }
        }
      }
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_map"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        title: {
          text: "单位：万元",
          top: 20,
          left: 20,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        tooltip: {
          backgroundColor: "rgba(0,44,117,0.8)",
          textStyle: {
            color: "#fff",
          },
          triggerOn: "mousemove",
          formatter: function (e) {
            return (
              "名称：" +
              e.name +
              "<br />订单数：" +
              e.data?.tooltip?.orderNum +
              "<br />交易额：" +
              e.value
            );
          },
        },
        geo: {
          show: true,
          map: "china",
          label: {
            show: false,
          },
          roam: false,
          itemStyle: {
            normal: {
              shadowColor: "#1B479A",
              shadowOffsetX: -5,
              shadowOffsetY: 10,
            },
          },
        },
        series: [
          {
            data: dataList,
            type: "map",
            map: "china",
            // zoom: 1.2,
            // aspectScale: 1,
            label: {
              // 默认文本标签样式
              normal: {
                color: "black",
                show: true,
              },
              // 高亮文本标签样式
              emphasis: {
                color: "yellow",
                fontSize: 22,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              // 默认区域样式
              normal: {
                // 区域背景透明
                areaColor: "#2D81B1",
                borderColor: "#74C39A",
                borderWidth: 3,
              },
              // 高亮区域样式
              emphasis: {
                // 高亮区域背景色
                areaColor: "#EE741A",
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.bg_color {
  background-color: #00032f;
}

.screen_pc {
  width: 100%;
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s;
}

.body {
  width: 100%;
  height: 100%;
  background-image: url(@/assets/img/bg_pc.png);
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 15px 50px;
}

.header {
  display: flex;
  justify-content: center;
}

.logo {
  width: 130px;
  height: 38px;
  display: block;
  position: absolute;
  left: 0;
}

.time_info_left,
.time_info_right {
  position: relative;
  width: 530px;
  height: 57px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 120px;
}

.time_info_left {
  justify-content: flex-end;
  background-image: url(@/assets/img/bg_info_left.png);
}
.time_info_right {
  justify-content: flex-start;
  background-image: url(@/assets/img/bg_info_right.png);
}

.title {
  width: 691px;
  height: 78px;
  background-image: url(@/assets/img/bg_title.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  font-weight: 400;
  font-size: 46px;
  line-height: 46px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 13px;
}

.block_main {
  display: flex;
}

.block_main > div > div:not(:last-child) {
  margin-bottom: 10px;
}

.block_first_left {
  width: 590px;
}

.block_first_center {
  width: 600px;
  margin: 0 20px;
}

.block_first_right {
  width: 590px;
}

.block_cell1 {
  height: 510px;
}

.gauge_groups {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gauge_groups > div {
  margin-top: 10px;
}

.gauge_groups > div:not(:nth-child(4n)) {
  margin-right: 47px;
}

#echarts_gauge1,
#echarts_gauge2,
#echarts_gauge3,
#echarts_gauge4,
#echarts_gauge5,
#echarts_gauge6,
#echarts_gauge7,
#echarts_gauge8 {
  width: 82px;
  height: 77px;
}

#echarts_line1 {
  width: 100%;
  height: 260px;
}

.block_cell2 {
  height: 270px;
}

.rank_list {
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 40px;
}

.rank_list .rank_list_head {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rank_list_head > div {
  width: 20%;
  text-align: center;
}

.rank_list_body {
  margin-top: 10px;
}

.rank_list .rank_list_cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rank_list_cell > div {
  width: 20%;
  text-align: center;
  font-size: 20px;
}

.block_cell3 {
  height: 172px;
}

.rongzi_list {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rongzi_list > div {
  width: 45%;
  height: 60px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;
}

.block_cell4 {
  height: 55px;
}

.info_span {
  display: flex;
  align-items: center;
}

/* .info_span:not(:last-child) {
  margin-bottom: 10px;
} */

.info_span > div:first-child {
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}

.info_span > div:last-child {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}

.block_cell5 {
  height: 440px;
}

#echarts_map {
  width: 100%;
  height: 100%;
}

.block_cell6 {
  height: 180px;
}

.search_cell {
  position: absolute;
  right: 60px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  width: 138px;
  height: 30px;
}

.search_cell /deep/ .el-input__inner {
  border: 0;
  background-color: transparent;
  border-radius: 25px;
  width: 110px;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.search_cell img {
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 10px;
}

.news_list {
  box-sizing: border-box;
  padding: 0 30px;
  margin-top: 10px;
}

.news_cell {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 36px;
}

.news_cell_time {
  width: 80px;
  flex-shrink: 0;
  margin-right: 10px;
}

.news_cell_title {
  width: 440px;
}

.news_cell_arrow {
}

.block_cell7 {
  height: 106px;
}

.custom_carousel {
  width: 100%;
  height: 100%;
  background-color: #3d899e;
}

.custom_carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom_carousel video {
  width: 100%;
  height: 100%;
}

.swiper,
.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  color: #fff;
}

.block_cell8 {
  height: 150px;
}

.gauge_groups1 {
  display: flex;
  align-items: center;
}

#echarts_gauge9,
#echarts_gauge10,
#echarts_gauge11 {
  width: 33.3%;
  height: 100px;
}

.block_cell9 {
  height: 580px;
}

.hangqing_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.hangqing_cell {
  width: 33.3%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.hangqing_cell > div:first-child {
  font-weight: bold;
  font-size: 21px;
  color: #ffffff;
  margin-bottom: 10px;
}

.hangqing_cell > div:last-child {
  font-size: 21px;
  color: #ffffff;
}

#echarts_line2 {
  width: 100%;
  height: 280px;
}

.block_cell10 {
  height: 200px;
}

.category_head {
  padding-bottom: 0;
  width: calc(100% - 80px);
  height: 70px;
  margin-left: 40px;
  overflow-x: auto;
  display: flex;
  align-items: center;
}

.category_head::-webkit-scrollbar {
  width: 0;
}

.category_head > div {
  flex-shrink: 0;
  user-select: none;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  margin-right: 30px;
  cursor: pointer;
}

.category_head .active {
  position: relative;
  font-weight: bold;
  font-size: 26px;
  display: flex;
  justify-content: center;
}

.category_head .active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  background-image: url(@/assets/img/line_small.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 6px;
}

.news_list1 {
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
  height: 75px;
}

.news_cell1 {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
}

.news_cell1_time {
  width: 80px;
}

.news_cell1_title {
  width: 440px;
}

.news_cell1_arrow {
}

/deep/.el-pager li {
  background: transparent !important;
}

/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
  background: transparent !important;
  color: #fff;
}

/deep/.el-pagination {
  color: #fff;
}

.block_cell11 {
  height: 171px;
}

.rongzi_list1 {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rongzi_list1 > div {
  width: 30%;
  height: 60px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;
}
</style>
