import request from "@/utils/request";

// 获取大屏端的模块标题列表
export const getTitleInfo = (query) => {
  return request({
    url: "/bigscreen/bs/get",
    method: "GET",
    params: query,
  });
};
// 获取看版下展示的广告位文件列表
export const getAdvertList = (query) => {
  return request({
    url: "/bigscreen/advert/show",
    method: "GET",
    params: query,
  });
};
// 获取看版下展示的广告位文件列表
export const getAdvertListCeshi = (query) => {
  return request({
    url: "/api/index/test",
    method: "GET",
    params: query,
  });
};
// 获取指定类型数据组
export const getCustmDataList = (query) => {
  return request({
    url: "/bigscreen/custmData/show",
    method: "GET",
    params: query,
  });
};
// 获取数据概览信息+近几天折线数据节点
export const getDataView = (query) => {
  return request({
    url: "/bigscreen/dataView/show",
    method: "GET",
    params: query,
  });
};
// 获取今日行情概览信息+近几天折线数据节点
export const getDayPrice = (query) => {
  return request({
    url: "/bigscreen/dayPrice/show",
    method: "GET",
    params: query,
  });
};
// 查询最近全国数据列表(各省地图销量)
export const getProvMap = (query) => {
  return request({
    url: "/bigscreen/provMap/show",
    method: "GET",
    params: query,
  });
};
// 获取最新榜单列表
export const getRank = (query) => {
  return request({
    url: "/bigscreen/rank/show",
    method: "GET",
    params: query,
  });
};
// 获取[新闻动态]列表（+搜索）
export const getNewsList = (query) => {
  return request({
    url: "/bigscreen/newsTou/newsShow",
    method: "GET",
    params: query,
  });
};
// 获取所有头条类型
export const getAllType = (query) => {
  return request({
    url: "/bigscreen/newsTou/allType",
    method: "GET",
    params: query,
  });
};
// 获取不同类型[头条]列表
export const getTouList = (query) => {
  return request({
    url: "/bigscreen/newsTou/touShow",
    method: "GET",
    params: query,
  });
};
